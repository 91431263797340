<template>
	<div>
		<h1>Upload Raffle Participants</h1>

		<form
			enctype="multipart/form-data"
			novalidate
			v-if="isInitial || isSaving"
		>
			<div class="dropbox">
				<label for="upload" class="upload-button"
					>Select a CSV file to upload:</label
				>
				<input
					type="file"
					id="upload"
					name="csv"
					:disabled="isSaving"
					@change="filesChange($event.target.name, $event.target.files)"
					accept="text/csv"
					class="input-file"
				/>

				<p v-if="isInitial">
					Drag your file here to begin<br />or click the buton above to
					browse
				</p>
				<p v-if="isSaving">Uploading..</p>
			</div>
		</form>

		<div v-if="isSuccess">
			<div class="alert alert-primary" role="alert">
				The CSV file was uploaded successfully!
			</div>

			<p>
				<router-link to="/">Click here</router-link> to go back to the home
				page, or
				<a href="javascript:void(0)" @click="reset">click here</a> to upload
				again.
			</p>
		</div>

		<div v-if="isFailed">
			<div class="alert alert-danger">
				We are sorry, but your upload failed!
			</div>

			<p>
				<a href="javascript:void(0)" @click="reset">Try again</a>
			</p>
		</div>

		<button
			class="home-button floating-button"
			@click="goHome"
			alt="Go to the home page"
			title="Go to the home page"
		>
			<font-awesome-icon icon="home"></font-awesome-icon>
		</button>
	</div>
</template>

<style scoped>
.upload-button {
	display: block;
	margin-top: 1em;
	background-color: #020024;
	color: white;
	font-size: 1.1em;
	padding: 0.5em 1.05em;
}

.dropbox {
	outline: 2px dashed grey;
	outline-offset: -10px;
	background: #007090;
	color: white;
	padding: 10px 10px;
	min-height: 200px;
	position: relative;
	cursor: pointer;
}

.input-file {
	opacity: 0;
	width: 100%;
	height: 200px;
	position: absolute;
	cursor: pointer;
}

.dropbox:hover {
	background: #01a7c2;
}

.dropbox p {
	font-size: 1.2em;
	text-align: center;
	padding: 50px 0;
	color: white;
}

.home-button {
	bottom: 40px;
}
</style>

<script>
import * as axios from "axios";

const STATUS_INITIAL = 0,
	STATUS_SAVING = 1,
	STATUS_SUCCESS = 2,
	STATUS_FAILED = 3;

export default {
	name: "UploadRaffles",

	computed: {
		isInitial() {
			return this.currentStatus === STATUS_INITIAL;
		},

		isSaving() {
			return this.currentStatus === STATUS_SAVING;
		},

		isSuccess() {
			return this.currentStatus === STATUS_SUCCESS;
		},

		isFailed() {
			return this.currentStatus === STATUS_FAILED;
		},
	},

	data() {
		return {
			uploadedFiles: [],
			uploadError: null,
			currentStatus: null,
		};
	},

	methods: {
		reset() {
			this.currentStatus = STATUS_INITIAL;
			this.uploadedFiles = [];
			this.uploadError = null;
		},

		async save(formData) {
			this.currentStatus = STATUS_SAVING;

			await axios.post(`/raffle/upload`, formData);
			this.currentStatus = STATUS_SUCCESS;
		},

		filesChange(fieldName, fileList) {
			const formData = new FormData();

			if (!fileList.length) return;

			Array.from(Array(fileList.length).keys()).map((x) => {
				formData.append(fieldName, fileList[x], fileList[x].name);
			});

			this.save(formData);
		},

		goHome() {
			this.$router.push({ name: "Home" });
		},
	},

	mounted() {
		this.reset();
	},
};
</script>
